.header-container {
    width: 100%;
    background: rgba(255, 255, 255, 0.587);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    backdrop-filter: blur(11.5px);
    -webkit-backdrop-filter: blur(11.5px);
    border-radius: 12px;
    border: 4px solid rgb(255, 255, 255, 0);
    transition: all 0.09s ease-in-out;

    .header-content {
        padding: 32px;
        align-items: center;
        font-weight: bolder;
        justify-content: space-between;
        display: flex;

        .made {
            flex-grow: 2;
            text-align: center;
        }

        .side {
            width: 25%;
        }

        .soc {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.socials {
    display: flex;
    gap: 15px;

    a {
        all: unset;
        cursor: pointer;
    }

    .social {
        height: 24px;
        transition: all 0.05s ease-in-out;
        filter: invert(0%) sepia(3%) saturate(12%) hue-rotate(103deg) brightness(105%) contrast(105%);

    }

    .twitch:hover {
        filter: invert(22%) sepia(87%) saturate(1368%) hue-rotate(241deg) brightness(93%) contrast(84%);
    }

    .twitter:hover {
        filter: invert(54%) sepia(77%) saturate(3263%) hue-rotate(164deg) brightness(99%) contrast(101%);
    }

    .instagram:hover {
        filter: invert(37%) sepia(83%) saturate(899%) hue-rotate(311deg) brightness(88%) contrast(103%);
    }

    .kofi:hover {
        filter: invert(56%) sepia(36%) saturate(6389%) hue-rotate(328deg) brightness(107%) contrast(103%);
    }
}

@media only screen and (max-width: 1260px) {
    .header-container {
        .header-content {
            flex-direction: column;
            gap: 20px;
            text-align: center;

            .side {
                width: 100%;
                justify-content: center;
            }
        }
    }
}
