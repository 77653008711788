.slideshow-container {
    position: relative;
    height: 400px;
    background-color: white;
    border-radius: 10px 10px 0px 0px;


    img {
        height: 100%;
    }

    .controls {
        background: rgb(255, 255, 255);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 65%);
        display: inline-flex;
        position: absolute;
        align-items: flex-end;
        flex-direction: row;
        justify-content: space-between;
        bottom: 0;
        right: 0;
        width: 100%;
        display: flex;
        gap: 10px;

        .types {
            padding: 10px;
            display: flex;
            flex-direction: row;
            gap: 5px;

            .type {
                border-radius: 50%;
                background-color: white;
                border: 2px solid black;
                overflow: hidden;
                height: 28px;
                width: 28px;
                border-radius: 50%;

                img {
                    height: 28px;
                    width: 28px;
                    border-radius: 50%;
                }
            }
        }


        .colors {
            padding: 10px;
            display: flex;
            align-items: center;
            gap: 10px;

            .control {
                width: 10px;
                height: 10px;
                padding: 2px;
                border-radius: 4px;
                border: 2px solid #111;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

}
