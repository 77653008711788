@import url('https://fonts.cdnfonts.com/css/londrina-outline');

.success-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    background: rgb(255, 255, 255);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.5px);
    -webkit-backdrop-filter: blur(11.5px);
    padding: 42px 42px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    font-size: 18px;
    width: 600px;

    .img {
        width: 100px;
        height: 100px;

        img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            animation: spin 8s infinite linear;
        }
    }

    .address {
        text-align: center;

        b {
            color: rgb(177, 86, 1);
        }
    }

    .header {
        font-size: 40px;
        text-align: center;

        span {
            font-weight: bolder;
            color: rgb(177, 86, 1);
        }
    }

    .mail {
        text-align: center;
    }

    .contact {
        padding-top: 10px;

        a {
            text-decoration: none;
            background-color: rgb(244, 130, 23);
            color: white;
            padding: 12px 24px;
            border-radius: 12px;
        }
    }
}


@media only screen and (max-width: 800px) {
    .success-container {
        position: relative;
        box-shadow: none;
        padding: 100px;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
