@import url('https://fonts.cdnfonts.com/css/groovy-cartoon-expanded-round');
@import url('https://fonts.cdnfonts.com/css/londrina-outline');
@import url('https://fonts.cdnfonts.com/css/roboto');

* {
  user-select: none;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  color: #02011e;
  font-size: 14px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none;
}

#root {
  /*background-image: url('./assets/backgrounds/background.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.centered {
  box-sizing: border-box;
  width: 1200px;
}

.box {
  background: white;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0);
  border-bottom: 4px solid rgba(0, 0, 0);
  overflow: hidden;
}

@media only screen and (max-width: 1260px) {
  .centered {
    width: 100%;
    padding: 0px 30px !important;
  }
}
