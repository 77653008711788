@import url('https://fonts.cdnfonts.com/css/londrina-outline');

.home-container {
    position: relative;
    min-height: 100vh;
    background-color: #F9F9F9;
    //animation: shift 15s infinite;

    &.no-scroll {
        overflow: hidden !important;
        -webkit-overflow-scrolling: touch;
    }

    .home-container-center {
        gap: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .home-header {
            width: 100%;
            z-index: 2;
            position: fixed;
            display: flex;
            justify-content: center;
            align-items: center;
            backdrop-filter: blur(11.5px);
            -webkit-backdrop-filter: blur(11.5px);
        }

        .home-content {
            display: flex;
            flex-direction: column;
            gap: 50px;
            padding: 0px 50px;
            padding-top: 120px;

            .items {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 20px;
                grid-auto-rows: minmax(100px, auto);
            }
        }

        .home-footer {
            padding: 25px 0px;
        }
    }

    .kart {
        background-color: white;
        position: fixed;
        z-index: 20;
        top: 0;
        right: 0;
        width: 400px;
        border-radius: 12px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(11.5px);
        -webkit-backdrop-filter: blur(11.5px);
        transition: all 0.09s ease-in-out;
        padding: 24px;
        margin: 12px -24px;
        max-height: calc(100% - 75px);
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;


        .kart-items {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-top: 12px;
        }

        .bottom-kart {
            padding-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .subtotal {
                text-align: right;

                div:nth-child(2) {
                    font-size: 2em;
                    font-weight: bolder;
                }
            }
        }



        .button {
            background-color: #FFA600;
            padding: 20px;
            border-radius: 8px;
            text-align: center;
            font-size: 1.5em;
            transition: 0.1s all ease-in-out;

            &.disabled {
                &:hover {
                    cursor: not-allowed;

                }

                opacity: 0.5;
                background-color: #DDD;
            }

            &:hover {
                cursor: pointer;
            }

            &:active {
                transform: translateY(2px);
            }
        }

        .item-in-kart {
            display: flex;
            padding: 8px 8px 8px 0px;
            align-items: center;
            gap: 10px;
            border-bottom: 1px solid rgb(0, 0, 0, 0.1);

            .img {
                width: 50px;
                height: 50px;
                border: 1px solid rgb(0, 0, 0, 0.5);
                border-radius: 8px;
            }

            .lbl {
                flex-grow: 1;
            }

            .delete-icon {

                img {
                    opacity: 0.25;
                    width: 30px;
                    height: 30px;

                    &:hover {
                        opacity: 1;
                        cursor: pointer;
                    }
                }
            }

        }
    }

    .country-selector {
        top: 0;
        left: 0;
        z-index: 50;
        position: absolute;
        width: 100vw;
        height: 100vh;
        overflow: hidden;

        .overlay {
            display: inline;
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.3);

        }

        .modale-country {
            position: absolute;
            background-color: white;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 55;
            padding: 32px;
            border-radius: 12px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);

            .c {
                display: flex;
                width: 100%;
                flex: 1;
                justify-content: space-around;

                div {
                    border-radius: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 24px;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.1);
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.background-decorator {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    font-weight: bolder;
    letter-spacing: 3px;
    font-size: 10vw;
    transform: rotate(-10deg) translate(-20%, -20%);
    opacity: 0.05;
    font-family: 'Londrina Outline', sans-serif;

    .slide {
        animation: slide 120s linear infinite;
    }

    .reverse {
        transform: translateX(20%);
        animation: slide 120s linear infinite reverse;

    }
}


@media only screen and (max-width: 800px) {
    .home-container {
        .home-container-center {
            .home-content {
                .items {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    grid-gap: 10px;
                    grid-auto-rows: minmax(100px, auto);
                }
            }
        }
    }
}

@keyframes shift {
    0% {
        backdrop-filter: saturate(80%) hue-rotate(0deg);
    }

    100% {
        backdrop-filter: saturate(80%) hue-rotate(360deg);
    }
}

@keyframes slide {
    from {
        transform: translateX(20%);
    }

    to {
        transform: translateX(0%);
    }
}
