.size {
    padding: 12px;

    &:hover {
        font-size: 1.1em;
        background-color: rgb(255, 189, 68);
        font-weight: bolder;
        cursor: pointer;
    }
}

.item-container {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.5px);
    -webkit-backdrop-filter: blur(11.5px);
    border-radius: 12px;
    border: 4px solid rgb(255, 255, 255, 0);
    transition: all 0.09s ease-in-out;

    &:hover {
        cursor: pointer;
        border: 4px solid rgba(238, 127, 37, 1);
        background: rgba(255, 255, 255, 0.75);
        box-shadow: 0 8px 32px 0 rgba(238, 127, 37, 0.1);

        .item-content {
            .item-cta {
                svg {
                    background-color: rgba(238, 127, 37, 1);

                    &:hover {
                        background-color: rgb(255, 68, 0);
                        fill: white !important;
                    }

                    &:active {
                        scale: 1;
                        transform: translateY(2px);
                    }
                }
            }
        }
    }

    .item-content {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 15px 15px 12px 15px;
        position: relative;
        flex-direction: column;



        .item-infos {
            z-index: 2;

            .title {
                text-transform: uppercase;
                font-size: 1.25em;
                transition: all 0.09s ease-in-out;
            }

            .price {
                font-size: 2em;
                font-weight: bold;

                .currency {
                    font-size: 0.75em;
                }
            }

            .description {
                text-align: justify;
                padding: 12px 0px;
            }
        }

        .item-cta {
            padding-top: 12px;
            width: 100%;

            svg {
                fill: black !important;
                height: 24px;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.065);
                padding: 8px 0px;
                border-radius: 8px;
                transition: all 0.09s ease-in-out;
            }
        }

    }
}

.slideshow {}
