.panel-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding: 20px 20px;

    border-bottom: 2px solid rgba(255, 255, 255, 0.196);
    box-shadow: 0px 10px 10px rgba(255, 255, 255, 0.01);
    transition: all 0.09s ease-in-out;

    .panel-title {
        .title {
            font-weight: bold;
            font-size: 2em;
        }

        flex-grow: 1;
    }

    .panel-image {
        img {
            border-radius: 50%;
            width: 45px;
        }
    }

    .panel-kart {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px;
        border-radius: 10px;
        transition: all 0.09s ease-in-out;

        img {
            width: 45px;
            opacity: 0.75;

            &:hover {
                cursor: pointer;
                opacity: 1;
            }
        }

        .cart-quantity {
            font-weight: bolder;
            font-size: 1.5em;
            width: 1.5em;
            height: 1.5em;
            line-height: 1.5em;
            background-color: #01052182;
            color: white;
            text-align: center;
            border-radius: 50%;
            padding: 8px;

        }

        .orange {
            background-color: rgb(255, 166, 0);
            color: #010521;
        }


        &:hover {
            cursor: pointer;
            background-color: rgba(255, 166, 0, 0.326);
        }
    }
}
